import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyAlphaOrPeriod]'
})
export class OnlyAlphaOrPeriodDirective {

  constructor(private el: ElementRef) { }

  @Input('appOnlyAlphaOrPeriod')
  public OnlyAlphaOrPeriod: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (this.OnlyAlphaOrPeriod) {
      const code = e.keyCode;

      if ([46, 8, 9, 27, 13, 110, 190, 37, 39].indexOf(code) !== -1) {
        return;
      }

      if (code < 65 || code > 90) {
        e.preventDefault();
      }
    }
  }

}
