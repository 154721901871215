import { Component, OnInit } from '@angular/core';
import { CaptureService } from '../capture/capture.service';
import { BASE_TAG } from '../capture/capture.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  signatures: object[];

  constructor(private data: CaptureService) {
    this.signatures = [];
  }

  async getStoredItems() {
    this.signatures = [];
    const keys = Object.keys(localStorage);

    keys.sort((a, b) => {
      const baseA = a.replace((BASE_TAG + '-'), '');
      const baseB = b.replace((BASE_TAG + '-'), '');
      let intA = 0;
      let intB = 0;

      try {
        intA = parseInt(baseA, 10);
        intB = parseInt(baseB, 10);
      } catch (err) {
        // fail silently
      }

      return intB - intA;
    });

    for (const key of keys) {
      if (key.startsWith(BASE_TAG)) {
        const val = await localStorage.getItem(key);
        this.signatures.push(JSON.parse(val));
      }
    }
  }

  ngOnInit() {
    this.getStoredItems();
    this.data.currentSavedSignatures.subscribe(signatures => this.signatures = signatures);
  }

  eventOccurred(elem, eventType) {
    let event;

    if (typeof(Event) === 'function') {
      event = new Event(eventType);
    } else {
      event = document.createEvent('Event');
      event.initEvent(eventType, true, true);
    }

    elem.dispatchEvent(event);
  }

  updateInputValue(o, prop, id, searchValue = null, newValue = null) {
    if (o.hasOwnProperty(prop)) {
      const el = document.getElementById(id) as HTMLInputElement;
      el.value = o[prop] ? o[prop] : '';

      if (el.value && searchValue !== null && newValue !== null) {
        el.value = o[prop].replace(searchValue, newValue);
      }

      this.eventOccurred(el, 'input');
    }
  }

  updateSelectValue(o, prop, id) {
    if (o.hasOwnProperty(prop)) {
      const el = document.getElementById(id) as HTMLSelectElement;
      el.value = o[prop] ? o[prop] : '';

      this.eventOccurred(el, 'change');
    }
  }

  async loadSignature(key) {
    try {
      const val = await localStorage.getItem(key);
      const obj = JSON.parse(val);

      this.updateInputValue(obj, 'firstName', 'first');
      this.updateInputValue(obj, 'middleName', 'middle', /.$/, '');
      this.updateInputValue(obj, 'lastName', 'last');
      this.updateInputValue(obj, 'credentials', 'credentials', /^, /, '');
      this.updateInputValue(obj, 'title', 'title');
      this.updateInputValue(obj, 'facility', 'facility');
      this.updateInputValue(obj, 'address', 'address');
      this.updateInputValue(obj, 'city', 'city');
      this.updateSelectValue(obj, 'state', 'state');
      this.updateInputValue(obj, 'zip', 'zip');
      this.updateInputValue(obj, 'telephone1', 'telephone1');
      this.updateInputValue(obj, 'telephone2', 'telephone2');
      this.updateInputValue(obj, 'telephone3', 'telephone3');
      this.updateInputValue(obj, 'extension', 'extension');
      this.updateInputValue(obj, 'mobile1', 'mobile1');
      this.updateInputValue(obj, 'mobile2', 'mobile2');
      this.updateInputValue(obj, 'mobile3', 'mobile3');
      this.updateInputValue(obj, 'fax1', 'fax1');
      this.updateInputValue(obj, 'fax2', 'fax2');
      this.updateInputValue(obj, 'fax3', 'fax3');

      // console.log(obj);
    } catch (err) {
      // fail silently
    }
  }

}
