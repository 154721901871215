import { Directive, HostListener, Input } from '@angular/core';
import { CaptureService } from './capture/capture.service';
declare var $: any;


// !! in any place or file !!
// Type declarations for Clipboard API
// https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
interface Clipboard {
  writeText(newClipText: string): Promise<void>;
  // Add any other methods you need here.
}
interface NavigatorClipboard extends Navigator {
  // Only available in a secure context.
  readonly clipboard?: Clipboard;
}


@Directive({
  selector: '[appCopyHtmlToClipboard]'
})
export class CopyHtmlToClipboardDirective {

  markup: string;

  @Input('appCopyHtmlToClipboard')
  public payload: string;

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {

    event.preventDefault();

    let notificationMessage = 'The signature could not be copied.';
    let notificationType = 'danger'; // 'info', 'warning', 'success', 'danger', 'default'

    if (this.markup) {
      // console.log(this.markup);

      if ((navigator as NavigatorClipboard).clipboard) {
        notificationMessage = 'The signature was copied to your clipboard.';
        notificationType = 'info';
        (navigator as NavigatorClipboard).clipboard.writeText(this.markup);
      }
    }

    $('.page-content-wrapper').pgNotification({
      style: 'flip',
      message: notificationMessage,
      position: 'top-right',
      timeout: 0,
      type: notificationType
    }).show();

  }

  constructor(private data: CaptureService) {
    this.data.currentMarkup.subscribe(markup => this.markup = markup);
  }

}
