import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gsuite',
  templateUrl: './gsuite.component.html',
  styleUrls: ['./gsuite.component.css']
})
export class GsuiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
