import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-owa',
  templateUrl: './owa.component.html',
  styleUrls: ['./owa.component.css']
})
export class OwaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
