import { Component, OnInit } from '@angular/core';
import { CaptureService } from '../capture/capture.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {

  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  email: string;
  pronouns: string;
  telephone1: string;
  telephone2: string;
  telephone3: string;
  extension: number;
  mobile1: string;
  mobile2: string;
  mobile3: string;
  fax1: string;
  fax2: string;
  fax3: string;

  constructor(private data: CaptureService) { }

  ngOnInit() {
    this.data.currentFirstName.subscribe(firstName => this.firstName = firstName);
    this.data.currentMiddleName.subscribe(middleName => this.middleName = middleName);
    this.data.currentLastName.subscribe(lastName => this.lastName = lastName);
    this.data.currentTitle.subscribe(title => this.title = title);
    this.data.currentEmail.subscribe(email => this.email = email);
    this.data.currentPronouns.subscribe(pronouns => this.pronouns = pronouns);
    this.data.currentTelephone1.subscribe(telephone1 => this.telephone1 = telephone1);
    this.data.currentTelephone2.subscribe(telephone2 => this.telephone2 = telephone2);
    this.data.currentTelephone3.subscribe(telephone3 => this.telephone3 = telephone3);
    this.data.currentExtension.subscribe(extension => this.extension = extension);
    this.data.currentMobile1.subscribe(mobile1 => this.mobile1 = mobile1);
    this.data.currentMobile2.subscribe(mobile2 => this.mobile2 = mobile2);
    this.data.currentMobile3.subscribe(mobile3 => this.mobile3 = mobile3);
    this.data.currentFax1.subscribe(fax1 => this.fax1 = fax1);
    this.data.currentFax2.subscribe(fax2 => this.fax2 = fax2);
    this.data.currentFax3.subscribe(fax3 => this.fax3 = fax3);
  }

}
