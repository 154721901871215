import { Component, OnInit } from '@angular/core';
import { CaptureService } from '../capture/capture.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;

const AVAILABLE_TAGS: string[] = ['a', 'br', 'img', 'span', 'table', 'tbody', 'td', 'tr'];
const CONTENT_TAGS: string[] = ['#text'];
const AVAILABLE_ATTRIBUTES: string[] = ['alt', 'border', 'cellpadding', 'cellspacing', 'height', 'href', 'src', 'style', 'width'];
const NO_CLOSING_TAGS: string[] = ['br', 'img'];
const MI_REGEX = /^[a-zA-Z.]$/;
const NUM_3_REGEX = /^(\d{3})$/;
const NUM_4_REGEX = /^(\d{4})$/;
const EMAIL_REGEX = /\S+@\S+\.\S+/;
const EXT_REGEX = /^\d*$/;
export const BASE_TAG = 'VA';

@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.css']
})
export class CaptureComponent implements OnInit {

  firstName: string;
  middleName: string;
  lastName: string;
  credentials: string;
  title: string;
  email: string;
  pronouns: string;
  telephone1: string;
  telephone2: string;
  telephone3: string;
  extension: number;
  mobile1: string;
  mobile2: string;
  mobile3: string;
  fax1: string;
  fax2: string;
  fax3: string;

  markup: string;
  form: FormGroup;
  timeout: number;

  constructor(private data: CaptureService) {
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.credentials = null;
    this.title = null;
    this.email = null;
    this.pronouns = null;
    this.telephone1 = null;
    this.telephone2 = null;
    this.telephone3 = null;
    this.extension = null;
    this.mobile1 = null;
    this.mobile2 = null;
    this.mobile3 = null;
    this.fax1 = null;
    this.fax2 = null;
    this.fax3 = null;
  }

  ngOnInit() {
    this.form = new FormGroup({
      first: new FormControl(this.firstName, [
        Validators.required
      ]),
      middle: new FormControl(this.middleName, [
        Validators.maxLength(5),
        Validators.pattern(MI_REGEX)
      ]),
      last: new FormControl(this.lastName, [
        Validators.required
      ]),
      title: new FormControl(this.title, [
        Validators.required
      ]),
      email: new FormControl(this.email, [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern(EMAIL_REGEX)
      ]),
      pronouns: new FormControl(this.pronouns, []),
      telephone1: new FormControl(this.telephone1, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(NUM_3_REGEX)
      ]),
      telephone2: new FormControl(this.telephone2, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(NUM_3_REGEX)
      ]),
      telephone3: new FormControl(this.telephone3, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern(NUM_4_REGEX)
      ]),
      extension: new FormControl(this.extension, [
        Validators.pattern(EXT_REGEX)
      ]),
      mobile1: new FormControl(this.mobile1, [
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(NUM_3_REGEX)
      ]),
      mobile2: new FormControl(this.mobile2, [
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(NUM_3_REGEX)
      ]),
      mobile3: new FormControl(this.mobile3, [
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern(NUM_4_REGEX)
      ]),
      fax1: new FormControl(this.fax1, [
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(NUM_3_REGEX)
      ]),
      fax2: new FormControl(this.fax2, [
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(NUM_3_REGEX)
      ]),
      fax3: new FormControl(this.fax3, [
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern(NUM_4_REGEX)
      ]),
    });
  }

  formatNumberComponent(num: number, padBy: number) {
    return num.toString().trim().padStart(padBy, '0');
  }

  iterator(el) {
    const tag = el.nodeName.toLowerCase();
    let markup = '';

    if (AVAILABLE_TAGS.includes(tag)) {
      markup = '<' + tag;

      if (el.hasAttributes()) {
        for (const attr of el.attributes) {
          if (AVAILABLE_ATTRIBUTES.includes(attr.nodeName)) {
            markup += ' ' + attr.nodeName + '="';
            markup += attr.nodeValue + '"';
          }
        }
      }

      markup += (!NO_CLOSING_TAGS.includes(tag)) ? '>' : '/>';
    }

    if (el.hasChildNodes() && el.childNodes.length > 0) {
      for (const child of el.childNodes) {
        markup += this.iterator(child);
      }
    } else if (CONTENT_TAGS.includes(tag)) {
      markup += el.nodeValue;
    }

    if (AVAILABLE_TAGS.includes(tag) && !NO_CLOSING_TAGS.includes(tag)) {
      markup += '</' + tag + '>';
    }

    return markup;
  }

  captureSignature() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      const signature = document.getElementById('signature-preview') as HTMLElement;
      this.markup = this.iterator(signature);
      this.data.changeMarkup(this.markup);

      const textareas = Array.from(document.querySelectorAll<HTMLTextAreaElement>('.voa-cp'));
      textareas.forEach((textarea) => {
        // console.log(textarea);
        textarea.value = this.markup;
      });
    }, 1000);
  }

  onFirstNameChange(firstName: string) {
    this.firstName = firstName.trim().toUpperCase();
    this.data.changeFirstName(this.firstName);

    this.captureSignature();
  }

  onMiddleNameChange(middleName: string) {
    const val = middleName.trim().toUpperCase();
    const formatted = (val.length > 0 && !val.endsWith('.')) ? (val + '.') : val;
    this.middleName = formatted.length > 0 ? formatted : null;
    this.data.changeMiddleName(this.middleName);

    this.captureSignature();
  }

  onLastNameChange(lastName: string) {
    this.lastName = lastName.trim().toUpperCase();
    this.data.changeLastName(this.lastName);

    this.captureSignature();
  }

  onTitleChange(title: string) {
    this.title = title.trim();
    this.data.changeTitle(this.title);

    this.captureSignature();
  }

  onEmailChange(email: string) {
    this.email = email.trim();
    this.data.changeEmail(this.email);

    this.captureSignature();
  }

  onPronounsChange(pronouns: string) {
    this.pronouns = pronouns.trim();
    this.data.changePronouns(this.pronouns);

    this.captureSignature();
  }

  onTelephone1Change(telephone1: number) {
    this.telephone1 = telephone1 ? this.formatNumberComponent(telephone1, 3) : null;
    this.data.changeTelephone1(this.telephone1);

    this.captureSignature();
    this.updateInputFocus(telephone1, 3, 'telephone2');
  }

  onTelephone2Change(telephone2: number) {
    this.telephone2 = telephone2 ? this.formatNumberComponent(telephone2, 3) : null;
    this.data.changeTelephone2(this.telephone2);

    this.captureSignature();
    this.updateInputFocus(telephone2, 3, 'telephone3');
  }

  onTelephone3Change(telephone3: number) {
    this.telephone3 = telephone3 ? this.formatNumberComponent(telephone3, 4) : null;
    this.data.changeTelephone3(this.telephone3);

    this.captureSignature();
  }

  onExtensionChange(extension: number) {
    this.extension = extension ? extension : null;
    this.data.changeExtension(this.extension);

    this.captureSignature();
  }

  onMobile1Change(mobile1: number) {
    this.mobile1 = mobile1 ? this.formatNumberComponent(mobile1, 3) : null;
    this.data.changeMobile1(this.mobile1);

    this.captureSignature();
    this.updateInputFocus(mobile1, 3, 'mobile2');
  }

  onMobile2Change(mobile2: number) {
    this.mobile2 = mobile2 ? this.formatNumberComponent(mobile2, 3) : null;
    this.data.changeMobile2(this.mobile2);

    this.captureSignature();
    this.updateInputFocus(mobile2, 3, 'mobile3');
  }

  onMobile3Change(mobile3: number) {
    this.mobile3 = mobile3 ? this.formatNumberComponent(mobile3, 4) : null;
    this.data.changeMobile3(this.mobile3);

    this.captureSignature();
  }

  onFax1Change(fax1: number) {
    this.fax1 = fax1 ? this.formatNumberComponent(fax1, 3) : null;
    this.data.changeFax1(this.fax1);

    this.captureSignature();
    this.updateInputFocus(fax1, 3, 'fax2');
  }

  onFax2Change(fax2: number) {
    this.fax2 = fax2 ? this.formatNumberComponent(fax2, 3) : null;
    this.data.changeFax2(this.fax2);

    this.captureSignature();
    this.updateInputFocus(fax2, 3, 'fax3');
  }

  onFax3Change(fax3: number) {
    this.fax3 = fax3 ? this.formatNumberComponent(fax3, 4) : null;
    this.data.changeFax3(this.fax3);

    this.captureSignature();
  }

  updateInputFocus(val, len, id) {
    if (val && val.toString().trim().length === len) {
      const el = document.getElementById(id) as HTMLInputElement;
      el.focus();
      el.setSelectionRange(0, el.value.length);
    }
  }

  resetForm() {
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.credentials = null;
    this.title = null;
    this.email = null;
    this.pronouns = null;
    this.telephone1 = null;
    this.telephone2 = null;
    this.telephone3 = null;
    this.extension = null;
    this.mobile1 = null;
    this.mobile2 = null;
    this.mobile3 = null;
    this.fax1 = null;
    this.fax2 = null;
    this.fax3 = null;
    this.data.changeFirstName(this.firstName);
    this.data.changeMiddleName(this.middleName);
    this.data.changeLastName(this.lastName);
    this.data.changeTitle(this.title);
    this.data.changeEmail(this.email);
    this.data.changePronouns(this.pronouns);
    this.data.changeTelephone1(this.telephone1);
    this.data.changeTelephone2(this.telephone2);
    this.data.changeTelephone3(this.telephone3);
    this.data.changeExtension(this.extension);
    this.data.changeMobile1(this.mobile1);
    this.data.changeMobile2(this.mobile2);
    this.data.changeMobile3(this.mobile3);
    this.data.changeFax1(this.fax1);
    this.data.changeFax2(this.fax2);
    this.data.changeFax3(this.fax3);

    this.captureSignature();
  }

  leftpad(val, len = 2, char = '0'): string {
    return (String(char).repeat(len) + String(val)).slice(String(val).length);
  }

  formatDate(d): string {
    return d.getFullYear()
      + '-' + this.leftpad((d.getMonth() + 1), 2)
      + '-' + this.leftpad(d.getDate(), 2)
      + ' ' + this.leftpad(d.getHours(), 2)
      + ':' + this.leftpad(d.getMinutes(), 2)
      + ':' + this.leftpad(d.getSeconds(), 2);
  }

  submitForm() {
    if (this.form.valid) {
      const now = new Date();
      const tag = BASE_TAG + '-' + now.getTime();
      const val = {
        key: tag,
        createdAt: this.formatDate(now),
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        credentials: this.credentials,
        title: this.title,
        email: this.email,
        pronouns: this.pronouns,
        telephone1: this.telephone1,
        telephone2: this.telephone2,
        telephone3: this.telephone3,
        extension: this.extension,
        mobile1: this.mobile1,
        mobile2: this.mobile2,
        mobile3: this.mobile3,
        fax1: this.fax1,
        fax2: this.fax2,
        fax3: this.fax3,
        markup: this.markup
      };

      localStorage.setItem(tag, JSON.stringify(val));

      const storedItems = [];
      const keys = Object.keys(localStorage);

      for (const key of keys) {
        if (key.startsWith(BASE_TAG)) {
          const v = localStorage.getItem(key);
          storedItems.push(JSON.parse(v));
        }
      }

      this.data.changeSavedSignatures(storedItems);

      $('.page-content-wrapper').pgNotification({
        style: 'flip',
        message: 'The signature was saved.',
        position: 'top-right',
        timeout: 0,
        type: 'info' // 'info', 'warning', 'success', 'danger', 'default'
      }).show();
    }
  }

}
