import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaptureService {

  private firstNameSource = new BehaviorSubject('JOHN');
  currentFirstName = this.firstNameSource.asObservable();

  private middleNameSource = new BehaviorSubject(null);
  currentMiddleName = this.middleNameSource.asObservable();

  private lastNameSource = new BehaviorSubject('SMITH');
  currentLastName = this.lastNameSource.asObservable();

  private titleSource = new BehaviorSubject('Title');
  currentTitle = this.titleSource.asObservable();

  private emailSource = new BehaviorSubject('example@voa.org');
  currentEmail = this.emailSource.asObservable();

  private pronounsSource = new BehaviorSubject(null);
  currentPronouns = this.pronounsSource.asObservable();

  private telephone1Source = new BehaviorSubject('xxx');
  currentTelephone1 = this.telephone1Source.asObservable();

  private telephone2Source = new BehaviorSubject('xxx');
  currentTelephone2 = this.telephone2Source.asObservable();

  private telephone3Source = new BehaviorSubject('xxxx');
  currentTelephone3 = this.telephone3Source.asObservable();

  private extensionSource = new BehaviorSubject(null);
  currentExtension = this.extensionSource.asObservable();

  private mobile1Source = new BehaviorSubject(null);
  currentMobile1 = this.mobile1Source.asObservable();

  private mobile2Source = new BehaviorSubject(null);
  currentMobile2 = this.mobile2Source.asObservable();

  private mobile3Source = new BehaviorSubject(null);
  currentMobile3 = this.mobile3Source.asObservable();

  private fax1Source = new BehaviorSubject(null);
  currentFax1 = this.fax1Source.asObservable();

  private fax2Source = new BehaviorSubject(null);
  currentFax2 = this.fax2Source.asObservable();

  private fax3Source = new BehaviorSubject(null);
  currentFax3 = this.fax3Source.asObservable();

  private savedSignaturesSource = new BehaviorSubject([]);
  currentSavedSignatures = this.savedSignaturesSource.asObservable();

  private markupSource = new BehaviorSubject(null);
  currentMarkup = this.markupSource.asObservable();

  constructor() { }

  changeFirstName(firstName: string) {
    this.firstNameSource.next(firstName);
  }

  changeMiddleName(middleName: string) {
    this.middleNameSource.next(middleName);
  }

  changeLastName(lastName: string) {
    this.lastNameSource.next(lastName);
  }

  changeTitle(title: string) {
    this.titleSource.next(title);
  }

  changeEmail(email: string) {
    this.emailSource.next(email);
  }

  changePronouns(pronouns: string) {
    this.pronounsSource.next(pronouns);
  }

  changeTelephone1(telephone1: string) {
    this.telephone1Source.next(telephone1);
  }

  changeTelephone2(telephone2: string) {
    this.telephone2Source.next(telephone2);
  }

  changeTelephone3(telephone3: string) {
    this.telephone3Source.next(telephone3);
  }

  changeExtension(extension: number) {
    this.extensionSource.next(extension);
  }

  changeMobile1(mobile1: string) {
    this.mobile1Source.next(mobile1);
  }

  changeMobile2(mobile2: string) {
    this.mobile2Source.next(mobile2);
  }

  changeMobile3(mobile3: string) {
    this.mobile3Source.next(mobile3);
  }

  changeFax1(fax1: string) {
    this.fax1Source.next(fax1);
  }

  changeFax2(fax2: string) {
    this.fax2Source.next(fax2);
  }

  changeFax3(fax3: string) {
    this.fax3Source.next(fax3);
  }

  changeSavedSignatures(signatures: object[]) {
    this.savedSignaturesSource.next(signatures);
  }

  changeMarkup(markup: string) {
    this.markupSource.next(markup);
  }

}
