import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outlook',
  templateUrl: './outlook.component.html',
  styleUrls: ['./outlook.component.css']
})
export class OutlookComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
