import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yahoo',
  templateUrl: './yahoo.component.html',
  styleUrls: ['./yahoo.component.css']
})
export class YahooComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
