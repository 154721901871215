import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { GmailComponent } from './gmail/gmail.component';
import { GsuiteComponent } from './gsuite/gsuite.component';
import { AppleMailComponent } from './apple-mail/apple-mail.component';
import { IosMailComponent } from './ios-mail/ios-mail.component';
import { O365Component } from './o365/o365.component';
import { OutlookComponent } from './outlook/outlook.component';
import { OwaComponent } from './owa/owa.component';
import { ThunderbirdComponent } from './thunderbird/thunderbird.component';
import { YahooComponent } from './yahoo/yahoo.component';
import { ManualComponent } from './manual/manual.component';
import { SignatureComponent } from './signature/signature.component';
import { CaptureComponent } from './capture/capture.component';
import { NavigationComponent } from './navigation/navigation.component';
import { CopyToClipboardDirective } from './copy-to-clipboard.directive';
import { CopyHtmlToClipboardDirective } from './copy-html-to-clipboard.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { OnlyNumberOrDashDirective } from './only-number-or-dash.directive';
import { OnlyAlphaOrPeriodDirective } from './only-alpha-or-period.directive';

@NgModule({
  declarations: [
    AppComponent,
    GmailComponent,
    GsuiteComponent,
    AppleMailComponent,
    IosMailComponent,
    O365Component,
    OutlookComponent,
    OwaComponent,
    ThunderbirdComponent,
    YahooComponent,
    ManualComponent,
    SignatureComponent,
    CaptureComponent,
    NavigationComponent,
    CopyToClipboardDirective,
    CopyHtmlToClipboardDirective,
    OnlyNumberDirective,
    OnlyNumberOrDashDirective,
    OnlyAlphaOrPeriodDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
