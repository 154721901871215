import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-mail',
  templateUrl: './ios-mail.component.html',
  styleUrls: ['./ios-mail.component.css']
})
export class IosMailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
