import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thunderbird',
  templateUrl: './thunderbird.component.html',
  styleUrls: ['./thunderbird.component.css']
})
export class ThunderbirdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
