import { Directive, HostListener, Input } from '@angular/core';
import { CaptureService } from './capture/capture.service';
declare var $: any;

@Directive({
  selector: '[appCopyToClipboard]'
})
export class CopyToClipboardDirective {

  markup: string;

  @Input('appCopyToClipboard')
  public payload: string;

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {

    event.preventDefault();

    let notificationMessage = 'The signature could not be copied.';
    let notificationType = 'danger'; // 'info', 'warning', 'success', 'danger', 'default'

    if (this.markup) {
      // reset blur and range
      const activeEl = document.activeElement as HTMLElement;
      activeEl.blur();
      window.getSelection().removeAllRanges();

      const signature = document.getElementById('signature-preview');
      const range = document.createRange();
      range.selectNode(signature);
      window.getSelection().addRange(range);

      setTimeout(() => {
        const successful = document.execCommand('copy');

        if (successful) {
          notificationMessage = 'The signature was copied to your clipboard.';
          notificationType = 'info';
        } else {
          notificationMessage += ' Please use the alternative instructions.';
          notificationType = 'warning';
        }

        $('.page-content-wrapper').pgNotification({
          style: 'flip',
          message: notificationMessage,
          position: 'top-right',
          timeout: 0,
          type: notificationType
        }).show();

        if (successful) {
          window.getSelection().removeAllRanges();
        }
      }, 1200);
    } else {
      $('.page-content-wrapper').pgNotification({
        style: 'flip',
        message: notificationMessage,
        position: 'top-right',
        timeout: 0,
        type: notificationType
      }).show();
    }

  }

  constructor(private data: CaptureService) {
    this.data.currentMarkup.subscribe(markup => this.markup = markup);
  }

}
